import React from "react";
import {
  faFacebook,
  faInstagram,
  faInternetExplorer,
  faTiktok,
  faTwitter,
  faVimeo,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Platform } from "../../@core/types/domain/Platform";
import { Stylable } from "../../types/reactTypes";

interface PlatformIconProps extends Stylable {
  id: Platform;
}

interface Config {
  title: string;
  icon?: IconDefinition;
  color?: string;
}

const platformIconConfig: { [key in Platform]: Config } = {
  [Platform.YouTube]: {
    title: "YouTube",
    icon: faYoutube,
    color: "red",
  },
  [Platform.Vimeo]: {
    title: "Vimeo",
    icon: faVimeo,
    color: "#1fb8eb",
  },
  [Platform.Instagram]: {
    title: "Instagram",
    icon: faInstagram,
  },
  [Platform.Facebook]: {
    icon: faFacebook,
    title: "Facebook",
    color: "#3b5998",
  },
  [Platform.Twitter]: {
    icon: faTwitter,
    title: "Twitter",
    color: "#1da1f2",
  },
  [Platform.Web]: {
    icon: faInternetExplorer,
    title: "Web",
  },
  [Platform.Vkontacte]: {
    title: "VKontaktje",
  },
  [Platform.TikTok]: {
    title: "TikTok",
    icon: faTiktok,
    color: "black",
  },
  [Platform.Dailymotion]: {
    title: "Dailymotion",
  },
};

function PlatformIcon(props: PlatformIconProps) {
  const config: Config = platformIconConfig[props.id];
  return (
    <span data-tooltip={config ? config.title : props.id + ""}>
      <FontAwesomeIcon
        className={props.className}
        icon={config ? config.icon : faQuestion}
        color={config ? config.color : "inherit"}
        fixedWidth
      />
    </span>
  );
}

export default PlatformIcon;
