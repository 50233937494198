import React from "react";
import { Stylable, WithChildren } from "../../types/reactTypes";

interface AProps extends WithChildren, Stylable {
  href?: string;
  target?: string;
  title?: string;
}

function A(props: AProps) {
  if (!props.href)
    return <span className={props.className}>{props.children}</span>;
  return (
    <a
      href={props.href}
      target={props.target}
      className={props.className}
      title={props.title}
    >
      {props.children}
    </a>
  );
}

export default A;
