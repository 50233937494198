import React from "react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { VideoType } from "../../../@core/types/domain/video/VideoType";
import LinkButton from "../../../components/button/LinkButton";

interface VideoDownloadProps {
  video: Pick<VideoType, "id" | "downloadUrl" | "platformId">;
}

function VideoDownload({ video }: VideoDownloadProps) {
  const url = video.downloadUrl;
  if (!url) return null;

  return (
    <LinkButton
      url={url}
      icon={faDownload}
      target="_blank"
      title={"Download this video via vidpaw.com"}
      variant={"is-primary"}
    >
      Download
    </LinkButton>
  );
}

function getConvertUrl(url: string) {
  return `https://www.vidpaw.com/download/?url=${encodeURIComponent(url)}`;
}

export default VideoDownload;
