import React from "react";
import {
  ExternalVideoType,
  VideoType,
} from "@core/types/domain/video/VideoType";
import styled from "@emotion/styled";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { UserRole } from "@core/types/domain/user/UserRole";
import VideoSocialStats from "../../../views/videos/table/columns/VideoSocialStats";
import VideoPlatformLink from "../../../components/domain/video/VideoPlatformLink";
import { formatDateTime } from "../../../utils/dateUtils";
import A from "../../../components/links/A";
import { getExternalVideoUrl } from "../getExternalVideoUrl";
import { createEmailHref } from "../../../utils/jsUtils";
import LinkButton from "../../../components/button/LinkButton";
import { useUserRole } from "../../../store/login/loginHooks";
import VideoDownload from "./VideoDownload";
import { RequestDetectionButton } from "./actions/RequestDetectionButton";

interface VideoInfoProps {
  video: ExternalVideoType;
}

function VideoInfo(props: VideoInfoProps) {
  const role = useUserRole();
  const { video } = props;
  return (
    <Container>
      <Title>
        <A href={video.url} target={"_blank"}>
          {video.title}
        </A>
      </Title>
      <Row>
        <VideoPlatformLink video={video} />
        <span> - {formatDateTime(video.publishAt)}</span>
      </Row>
      {video.description && <Description>{video.description}</Description>}
      <Row>
        <VideoSocialStats video={video} showLength />
      </Row>
      <Actions>
        <VideoDownload video={video} />
        <SharePageButton video={video} />
        {role === UserRole.SuperAdmin && (
          // TODO this should eventually be removed
          <RequestDetectionButton videoId={video.id} />
        )}
      </Actions>
      {/*<pre>{JSON.stringify(video, null, 2)}</pre>*/}
    </Container>
  );
}

function SharePageButton({ video }: { video: VideoType }) {
  const url = getExternalVideoUrl(video.id);
  if (!url) return null;
  const from = video.channelName && ` from ${video.channelName}`;
  const href = createEmailHref(
    `OnlineControl Videolink${from}`,
    `Link to video: ${url}`
  );
  return (
    <LinkButton
      icon={faEnvelope}
      url={href}
      target={"_blank"}
      variant={"is-primary"}
    >
      Share
    </LinkButton>
  );
}

const Row = styled.div`
  margin-bottom: 14px;
`;

const Actions = styled(Row)`
  > a {
    margin-right: 14px;
  }
`;

const Container = styled.div``;

const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 12px;
  a {
    color: inherit;
    &:hover {
      opacity: 0.7;
    }
  }
`;

const Description = styled(Row)`
  background-color: white;
  border: 1px solid #e3e3e3;
  padding: 8px 14px;
  border-radius: 5px;
`;

export default VideoInfo;
