import React from "react";
import MinimalLayout from "../../layouts/blank/MinimalLayout";
import ExternalVideoPage from "./ExternalVideoPage";

interface VideoScreenProps {}

function ExternalVideoScreen(props: VideoScreenProps) {
  return (
    <MinimalLayout>
      <ExternalVideoPage />
    </MinimalLayout>
  );
}

export default ExternalVideoScreen;
