import React, { useEffect } from "react";
import { useFetchState } from "../../utils/hooks/useFetchState";
import { VideoType } from "../../@core/types/domain/video/VideoType";
import { getQueryParam } from "../../utils/jsUtils";
import { fetchPublicVideo } from "../../services/video/videoService";
import AppPageSpinner from "../../layouts/app/AppPageSpinner";
import Alert from "../../components/alert/Alert";
import VideoView from "./details/VideoView";

interface ExternalVideoPageProps {}

function ExternalVideoPage(props: ExternalVideoPageProps) {
  const [{ data, error, isLoading }, dispatch] = useFetchState<VideoType>();

  useEffect(() => {
    const id = getQueryParam("id", parseInt);
    if (!id) {
      dispatch.onFail(new Error("Missing id parameter"));
      return;
    }

    dispatch.setLoading();
    fetchPublicVideo(id).then(dispatch.onSuccess).catch(dispatch.onFail);
  }, []);

  if (error) {
    return (
      <Alert type={"danger"}>Video could not be loaded: {error.message}</Alert>
    );
  }

  if (isLoading || !data) {
    return <AppPageSpinner />;
  }

  return <VideoView video={data} />;
}

export default ExternalVideoPage;
