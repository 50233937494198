import React from "react";
import { css } from "@emotion/react";
import PlatformIcon from "../../../views/videos/PlatformIcon";
import A from "../../links/A";
import { VideoType } from "../../../@core/types/domain/video/VideoType";
import { getChannelUrl } from "../../../utils/domain/videoUtils";
import { platformById } from "../../../@core/types/domain/Platform";

interface VideoPlatformLinkProps {
  video: VideoType;
}

function VideoPlatformLink(props: VideoPlatformLinkProps) {
  const { video } = props;
  const channelUrl = getChannelUrl(video);
  const platform = platformById[video.platformId];
  return (
    <A href={channelUrl} target={"_blank"}>
      <PlatformIcon css={iconCss} id={video.platformId} />
      {video.channelName || (platform && platform.name)}
    </A>
  );
}

const iconCss = css`
  margin-right: 4px;
`;

export default VideoPlatformLink;
