import React from "react";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/react";
import { WithChildren } from "../../types/reactTypes";

type ResponsiveContainerProps = WithChildren;

function ResponsiveContainer(props: ResponsiveContainerProps) {
  return (
    <Container>{cloneElement(props.children, { css: contentCss })}</Container>
  );
}

const cloneElement = (element, props) =>
  jsx(element.type, {
    key: element.key,
    ref: element.ref,
    ...element.props,
    ...props,
  });

const Container = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* ratio 16x9 */
  height: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
`;

const contentCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default ResponsiveContainer;
