export enum Platform {
  YouTube = 1,
  Twitter = 2,
  Vimeo = 3,
  Facebook = 4,
  Instagram = 5,
  Dailymotion = 6,
  Web = 7,
  Vkontacte = 8,
  TikTok = 9,
}

export interface PlatformType {
  id: number;
  name: string;
}

export type ByPlatform<T> = Record<Platform, T>;

export const platforms: PlatformType[] = [
  { id: Platform.YouTube, name: "YouTube" },
  { id: Platform.Twitter, name: "Twitter" },
  { id: Platform.Vimeo, name: "Vimeo" },
  { id: Platform.Facebook, name: "Facebook" },
  { id: Platform.Instagram, name: "Instagram" },
  { id: Platform.Dailymotion, name: "Dailymotion" },
  { id: Platform.Web, name: "Web" },
  { id: Platform.Vkontacte, name: "Vkontacte" },
  { id: Platform.TikTok, name: "TikTok" },
];

export const platformById: { [key: string]: PlatformType } = platforms.reduce(
  (result, platform) => {
    result[platform.id] = platform;
    return result;
  },
  {}
);
