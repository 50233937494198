import React from "react";
import styled from "@emotion/styled";
import { ExternalVideoType } from "../../../@core/types/domain/video/VideoType";
import ResponsiveContainer from "../../../components/responsive/ResponsiveContainer";
import VideoInfo from "./VideoInfo";

interface ExternalVideoViewProps {
  video: ExternalVideoType;
}

function VideoView(props: ExternalVideoViewProps) {
  const { video } = props;
  return (
    <Container>
      <Row>
        <Left>
          {video.embedUrl && (
            <ResponsiveContainer>
              <iframe src={video.embedUrl} frameBorder={0} />
            </ResponsiveContainer>
          )}
          {!video.embedUrl && video.thumbnail && (
            <ResponsiveContainer>
              <img src={video.thumbnail} alt="" />
            </ResponsiveContainer>
          )}
        </Left>
        <Right>
          <VideoInfo video={video} />
        </Right>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  margin-left: 4px;
  margin-right: 4px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -14px;
  margin-right: -14px;
  margin-bottom: -14px;
  > div {
    min-width: 500px;
    max-width: 700px;
    flex: 1;
    margin-left: 14px;
    margin-right: 14px;
    margin-bottom: 14px;
  }
`;
const Left = styled.div`
  flex: 1;
`;
const Right = styled.div`
  flex: 1;
`;

const WidthWrapper = styled.div`
  max-width: 500px;
`;

export default VideoView;
